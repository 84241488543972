<template>
  <div class="card">
    <div class="card_header">
      <div class="card_level">
        {{eventCategoryName}}
      </div>
      <div class="card_names" v-if="event.participants.length === 2">
          <span :title="teams.awayTeam.name">{{teams.awayTeam.name}}</span> <span>@</span> <span :title="teams.homeTeam.name">{{teams.homeTeam.name}}</span>
      </div>
      <div v-else class="card_name">
        <span>{{event.name}}</span>
      </div>
      <div class="card_date">
        <span>
          {{time.month}}
          {{time.day}}
        </span>
        <span>
          {{time.hh}}
        </span>
        {{time.zone}}
      </div>
    </div>
    <div class="card_body">
      <div class="card_status">
        <event-status :event="event"/>
      </div>
      <div v-if="isAwayTeamFavoriteWithParticipants || isHomeTeamFavoriteWithParticipants || isEventFavoriteWithParticipants" class="star_status" contenthover="Favorites">
        <StarIcon  :width="26" :height="26" :boolean="true" />
          
      </div>
      <div class="card-images" v-if="event.participants.length === 2">
        <div>
          <image-component :path="teams.awayTeam.img" :alt="teams.awayTeam.name"/>
        </div>
        <span>@</span>
        <div>
          <image-component :path="teams.homeTeam.img" :alt="teams.homeTeam.name"/>
          </div>
      </div>
      <div v-else class="card-poster">
        <image-component :path="eventPoster" :alt="event.name"/>
      </div>
      <div class="card_message" v-if="message">{{message}}</div>
    </div>
    <EventButton :event="event" class="card_button"/>
  </div>
</template>

<script>
import EventStatus from "@/league/components/schedule/EventStatus";
import ImageComponent from "@/components/base-components/ImageComponent";
import StarIcon from '@/components/icons/Star';
import EventButton from "@/league/components/main/EventButton";
import {mapState, mapGetters } from "vuex";
import {MEDIA_TYPE} from "@/const";
import {getAwayHomeTeams} from "@/utils/helpers";

export default {
  name: "CWEventCard",
  components: {EventButton, ImageComponent, EventStatus,
    StarIcon,},
  props:{
    event: {
      type: Object,
      time_zone: String,
      event_utc_ts: Number,
    }
  },
  data(){
    return {
      timeZone: this.event?.time_zone || '',
      message: this.event.caption
    }
  },
  computed: {
    ...mapGetters({
      getFavorites: "getFavorites",
    }),
    ...mapState({
      eventCategories: state => state.event.eventCategoriesById,
    }),
    eventCategoryName(){
      return this.eventCategories?.[this.event.event_category_id]?.name || ''
    },
    teams(){
      return getAwayHomeTeams(this.event)
    },
    isAwayTeamFavoriteWithParticipants() {
    return this.getFavorites.some((favorite) => {
      return favorite.favorite_id === this.teams.awayTeam.team_id && favorite.favorite_type === "participant";
    });
    },
    isHomeTeamFavoriteWithParticipants() {
    return this.getFavorites.some((favorite) => {
      return favorite.favorite_id === this.teams.homeTeam.team_id && favorite.favorite_type === "participant";
    });
  },
  isEventFavoriteWithParticipants() {
  const checkChildrenItems = (items) => {
    return items.some((item) => {
      if (item.id === this.event.event_category_id) {
        return true;
      }
      if (item.children_items && item.children_items.length > 0) {
        return checkChildrenItems(item.children_items);
      }
      return false;
    });
  };
  return this.getFavorites.some((favorite) => {
    if (
      (favorite.favorite_id === this.event.event_category_id && favorite.favorite_type === "category") ||
      (favorite.favorite_type === "category" && favorite.children_items && favorite.children_items.length > 0 && checkChildrenItems(favorite.children_items))
    ) {
      return true;
    }
    return false;
  });
},
    time() {
      const {month, day, hh, zone} = this.getFormattedDate({
        ts: this.event.event_utc_ts,
        timeZone: this.timeZone,
        formatsOptions: {
          month: {month: 'short'},
          day: {day: '2-digit'},
          hh: {hour: '2-digit', hourCycle: 'h12', minute: '2-digit'}
        }
      })
      return {
        month,
        day,
        hh,
        zone
      }
    },
    eventPoster(){
      return this.event.media?.find((media) => media.media_type === MEDIA_TYPE.image)?.media_url
    }
  }
}
</script>

<style scoped lang="scss">
  @import "src/assets/css/colors";
  @import "src/assets/css/mixins";
  .card{
    font-size: .8rem;
    border-radius: .5rem;
    overflow: hidden;
    box-shadow: $card-shadow;
    display: flex;
    flex-direction: column;
    line-height: 1rem;
  }
  .card_header {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: .2rem .7rem;
    }
    border-bottom: 2px solid $text-tertiary-light;
  }
  .card_level{
    background: var(--card-header-background);
    color: $text-secondary;
    svg {
      width: 70%;
    }
  }
  .card_name{
    line-height: 1rem;
    height: 5rem;
    box-sizing: content-box;
  }
  .card_names{
    display: grid;
    grid-template-rows: 2fr 1fr 2fr;
    box-sizing: content-box;
    span{
      &:first-child,
      &:last-child{
        @include line-clamp(2);
      }
      &:first-child{
        align-self: flex-end;
      }
      &:last-child{
        align-self: flex-start;
      }
    }
  }
  .card_date{
    background: var(--card-header-background);
    color: $text-secondary;
    display: flex;
    flex-direction: column;
    span{
      white-space: nowrap;
    }
  }

  @media only screen and (min-width: 600px) {
.star_status[contenthover]:hover::after {
  content: attr(contenthover);
  position: absolute;
  top: -60%;
  left: -15px;
  font-size: 12px;  
  font-weight: 600;
  text-transform: none;
  color: black;
  font-style: normal;
  box-shadow: rgba(100, 100, 111, 1) 0px 7px 29px 0px;
}
}
  .card_body{
    position: relative;
    padding: 1rem;
    flex-grow: 1;
  }
  .card_status{
    position: absolute;
    z-index: 1;
    top: 1rem;
    left: 1rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  .star_status{
    position: absolute;
    z-index: 1;
    top: 1rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  .card-poster{
    padding: 1rem 4rem;
    img{
      width: 100%;
      @include ratio(calc(16 / 9));
    }
  }
  .card-images{
    @include ratio(calc(20 / 9));
    display: grid;
    grid-template-columns: 1fr 2rem 1fr;
    align-items: center;
    justify-content: center;
    div{
      display: flex;
      justify-content: flex-start;
      &:first-child{
        justify-content: flex-end;
      }
    }
    img{
      width: 60%;
    }
  }
  .card_message{
    border: 1px solid $text-tertiary-dark;
    border-radius: .3rem;
    padding: .2rem 1rem;
    width: max-content;
    max-width: 90%;
    margin: 0 auto;
  }
  .card_button{
    padding: 1rem;
    font-weight: 900;
    font-size: 1rem;
    &:hover{
      text-decoration: none;
    }
  }
</style>